.snackbar {
    visibility: hidden;
    min-width: 250px;
    max-width: 80%;
    margin: 0 auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    font-size: 17px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.snackbar.show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 3.5s;
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

.close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-left: 15px;
}

@media screen and (max-width: 600px) {
    .snackbar {
        font-size: 14px;
        padding: 12px;
    }

    .close-btn {
        font-size: 18px;
    }
}
