/*----------------------------------------------
*
* [Font Import]
*
* Poppins : https://fonts.google.com
* Montserrat : https://fonts.google.com
*
----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/*----------------------------------------------

[ALL CONTENTS]

1. Reset
2. General
3. Section
4. Helper Class
5. Header
6. Slider
7. Feature
8. Form and Field
9. List
10. Gallery
11. Footer
12. Modal
13. Miscellaneous
14. Keyframe
15. All Media Queries

----------------------------------------------*/

/*----------------------------------------------
1. Reset
HTML5 display-role reset for older browsers
----------------------------------------------*/

/* #region Reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

html {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
}

body {
    width: 100%;
    height: 100%;
    background-color: var(--primary-bg-color);
    font-family: var(--p-font);
    color: var(--primary-p-color);
}

body.odd {
    background-color: var(--secondary-bg-color);
}

body .odd {
    color: var(--secondary-p-color);
}

ol, ul {
    padding-left: 20px;
    text-align: left;
}

ol {
    list-style: decimal;
}

ul {
    list-style: disc;
}

ul ul {
    list-style: circle;
}

pre {
    display: block;
    margin: 1rem 0;
    color: var(--primary-color);
}

code {
    display: inline;
    margin: 1rem 0;
    color: var(--primary-color);
}

blockquote, q {
    display: block;
    margin: 2rem 0;
    quotes: none;
    quotes: none;
    font-style: italic;
    padding: 1rem 1.5rem;
    border-left: 5px solid var(--primary-bg-color-3);
}

.text-center blockquote:not(.text-left),
.text-center q:not(.text-left) {
    border-top: 5px solid var(--primary-bg-color-3);
    border-bottom: 5px solid var(--primary-bg-color-3);
    border-left: none;
}

.text-right blockquote,
.text-right q {
    border-right: 5px solid var(--primary-bg-color-3);
    border-left: none;
}

.odd blockquote, q {
    border-color: var(--primary-color);
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

blockquote p, q p {
    margin: 0;
}

.blockquote-footer {
    margin-top: 10px;
    font-size: 14px;
}

figure {
    margin-bottom: 1.5rem;
}

figcaption {
    margin-bottom: 1.5rem;
    font-style: italic;
    padding: 1rem 1.5rem;
    border-left: 5px solid var(--primary-bg-color-3);
}

.odd figcaption {
    border-left: 5px solid var(--secondary-bg-color-3);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* #endregion Reset */

/*----------------------------------------------
2. General
----------------------------------------------*/

/* #region General */

::selection {
    background: rgba(166, 175, 189, 0.3);
}

::-moz-selection {
    background: rgba(166, 175, 189, 0.3);
}


*, *:after, *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a:not(.btn), a:link:not(.btn), a:visited:not(.btn), a:hover:not(.btn), a:active:not(.btn) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--primary-color);
    text-decoration: none;
}

a:not([href]),
a:not([href]):hover,
a:not([href]) i,
a:not([href]) i:hover {
    color: var(--primary-b-color);
}

p {
    margin: 1.5rem 0;
    font-size: var(--p-size);
    font-weight: var(--p-weight);
    line-height: 1.5;
}

p:first-child {
    margin-top: 0;
}

p:last-child {
    margin-bottom: 0;
}

h1,
.slide-content .title {
    margin-bottom: 1.5rem;
    font-size: var(--h1-size);
    font-weight: var(--h1-weight);
    line-height: 1.2;
    font-family: var(--h1-font);
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.odd h1,
.odd .slide-content .title {
    color: var(--white-color);
}

h1 em,
.slide-content .title em {
    font-weight: 300;
    display: block;
    font-size: var(--h2-size);
}

h1 .featured {
    position: relative;
    display: inline-block;
    padding: 5px 15px;
    color: var(--primary-color);
}

.odd h1 .featured {
    color: var(--white-color);
}

h1 .featured:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
    opacity: 0.15;
}

h1 .featured span {
    position: relative;
}

h1 .featured.bottom {
    padding: 0;
}

h1 .featured.bottom:before {
    top: 50%;
    left: 3px;
    width: 100%;
    height: 42%;
    opacity: 0.15;
}

.odd h1 .featured.bottom:before {
    opacity: 0.5;
}

h1 .pre-title {
    position: relative;
    bottom: 10px;
    font-family: var(--p-font);
    font-size: 1rem;
    font-weight: 600;
}

.pre-title {
    width: fit-content;
    margin: auto;
    display: block;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--secondary-color);
}

h2 {
    margin: 1.5rem 0;
    font-size: var(--h2-size);
    font-weight: var(--h2-weight);
    line-height: 1.2;
    font-family: var(--h2-font);
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

h2 .featured {
    position: relative;
    display: inline-block;
    padding: 5px 15px;
    color: var(--primary-color);
}

.odd h2 .featured {
    color: var(--white-color);
}

h2 .featured:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
    opacity: 0.15;
}

.odd h2 .featured:before {
    background-color: var(--secondary-color);
}

h2 .featured span {
    position: relative;
}

h2 .featured.bottom {
    padding: 0;
}

h2 .featured.bottom:before {
    top: 50%;
    left: 3px;
    width: 100%;
    height: 42%;
    opacity: 0.15;
}

.odd h2 .featured.bottom:before {
    opacity: 0.5;
}

.title-icon {
    margin: 1.5rem 0;
}

.title-icon h2 .featured span {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-icon h2 .featured span .icon {
    margin-right: 10px;
}

.odd h2 {
    color: var(--white-color);
}

h3 {
    margin: 1.5rem 0;
    font-size: 2rem;
    font-weight: var(--h2-weight);
    line-height: 1.2;
    font-family: var(--h2-font);
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.odd h3 {
    color: var(--white-color);
}

h4 {
    margin: 1.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

h4 i {
    position: relative;
    display: inline-flex;
    vertical-align: baseline;
    font-size: 1.5rem;
    color: var(--primary-color);
}

.odd h4 {
    color: var(--secondary-t-color);
}

.odd h4 i {
    position: relative;
    color: var(--secondary-color);
}

h5 {
    margin: 1.5rem 0;
    font-size: 1.2rem;
    font-weight: var(--h2-weight);
    line-height: 1.2;
    font-family: var(--h2-font);
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.odd h5 {
    color: var(--white-color);
}

h6 {
    margin: 1.5rem 0;
    font-size: 1rem;
    font-weight: var(--h2-weight);
    line-height: 1.2;
    font-family: var(--h2-font);
    color: var(--primary-t-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.odd h6 {
    color: var(--white-color);
}

b, strong {
    font-weight: 700;
}

li {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

table {
    border-collapse: separate;
    border-spacing: 10px;
}

table td {
    padding: 10px 0;
}

table th {
    padding: 10px 0;
}

dt {
    margin: 15px 0;
}

address {
    margin-bottom: 1rem;
}

form {
    width: 100%;
}

form label {
    width: 100%;
}

input, select {
    width: 100%;
    height: 44px;
    background: no-repeat;
    box-shadow: none;
    padding: 0.7rem 1rem;
    border-color: var(--primary-l-color);
    border-width: 1px;
    border-radius: 2px;
}

textarea {
    width: 100%;
    height: auto;
    background: no-repeat;
    box-shadow: none;
    padding: 0.7rem 1rem;
    border-color: var(--primary-l-color);
    border-width: 1px;
    border-radius: 2px;
}

input:focus, textarea:focus, select:focus {
    background: no-repeat;
    color: inherit;
    outline: none;
    border-color: inherit;
    box-shadow: none;
}

input {
    border-color: var(--primary-l-color);
    color: var(--primary-t-color);
}

input:focus {
    border-color: var(--primary-t-color);
    color: var(--primary-t-color);
}

input[type="radio"] {
    height: initial;
}

input[type="submit"] {
    border: 1px solid;
    padding: 2.5px 10px;
    display: block;
    width: fit-content;
    max-width: 200px;
    text-align: center;
    color: var(--primary-color);
}

input[type="submit"]:hover {
    background-color: var(--primary-color);
    color: var(--primary-b-color);
    border-color: var(--primary-color);
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
}

textarea {
    height: 100px;
    resize: none;
    border-color: var(--primary-l-color);
    color: var(--primary-t-color);
}

textarea:focus {
    border-color: var(--primary-t-color);
    color: var(--primary-t-color);
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    line-height: 1.2;
    border-color: var(--primary-l-color);
    color: #6c757d;
}

select:focus {
    border-color: var(--primary-t-color);
    color: var(--primary-t-color);
}

select option {
    color: #2f323a;
}

hr {
    margin: 35px 0;
    border-top: 1px dashed var(--primary-l-color);
}

.odd hr {
    margin: 35px 15px;
    border-top: 1px dashed var(--secondary-l-color);
}

table {
    width: 100%;
}

tr:not(:last-child) {
    border-bottom: 1px solid;
    border-color: var(--primary-l-color);
}

.odd tr:not(:last-child) {
    border-bottom: 1px solid;
    border-color: var(--secondary-l-color);
}

td {
    padding: 10px 0;
}

/* #endregion General */

/*----------------------------------------------
3. Section
----------------------------------------------*/

/* #region Section */

section {
    position: relative;
    width: 100%;
    padding: 130px 0;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

section.hero       { background-color: var(--hero-bg-color); }
section.section-1  { background-color: var(--section-1-bg-color);  background-image: var(--section-1-bg-image); }
section.section-2  { background-color: var(--section-2-bg-color);  background-image: var(--section-2-bg-image); }
section.section-3  { background-color: var(--section-3-bg-color);  background-image: var(--section-3-bg-image); }
section.section-4  { background-color: var(--section-4-bg-color);  background-image: var(--section-4-bg-image); }
section.section-5  { background-color: var(--section-5-bg-color);  background-image: var(--section-5-bg-image); }
section.section-6  { background-color: var(--section-6-bg-color);  background-image: var(--section-6-bg-image); }
section.section-7  { background-color: var(--section-7-bg-color);  background-image: var(--section-7-bg-image); }
section.section-8  { background-color: var(--section-8-bg-color);  background-image: var(--section-8-bg-image); }
section.section-9  { background-color: var(--section-9-bg-color);  background-image: var(--section-9-bg-image); }
section.section-10 { background-color: var(--section-10-bg-color); background-image: var(--section-10-bg-image); }
section.section-11 { background-color: var(--section-11-bg-color); background-image: var(--section-11-bg-image); }
section.section-12 { background-color: var(--section-12-bg-color); background-image: var(--section-12-bg-image); }
section.section-13 { background-color: var(--section-13-bg-color); background-image: var(--section-13-bg-image); }
section.section-14 { background-color: var(--section-14-bg-color); background-image: var(--section-14-bg-image); }
section.section-15 { background-color: var(--section-15-bg-color); background-image: var(--section-15-bg-image); }

.section-2 {
    .card {
        flex-direction: column;
        height: 100%;

        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .items {
        .item {
            display: flex;
            flex-direction: column;
        }
    }
}

section.featured {
    position: relative;
}

section.featured:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc(100% - 30px);
    left: calc(50% - 70px);
    width: 150px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.25;
}

section.featured.right:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    width: 50%;
    border-bottom: 1px dashed var(--primary-l-color);
}

section.odd.featured.right:before {
    border-bottom: 1px dashed var(--secondary-l-color);
}

section.featured.left:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 50%;
    border-bottom: 1px dashed var(--primary-l-color);
}

section.odd.featured.left:before {
    border-bottom: 1px dashed var(--secondary-l-color);
}

section.featured.all:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed var(--primary-l-color);
}

section.featured.right .odd:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc(100% - 1px);
    right: 0;
    width: 50%;
    border-bottom: 1px dashed var(--secondary-l-color);
}

section.featured.left .odd:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc(100% - 1px);
    left: 0;
    width: 50%;
    border-bottom: 1px dashed var(--secondary-l-color);
}

section.featured.all .odd:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    border-bottom: 1px dashed var(--secondary-l-color);
}

section.odd.featured.all:before {
    border-bottom: 1px dashed var(--secondary-l-color);
}

main {
    padding: 0;
}

aside {
    padding: 0;
}

.container {
    max-width: 1170px;
    padding: 0;
}

.container.header {
    max-width: 1140px;
}

.container.header.full {
    max-width: 100%;
}

.container.header.full-grid {
    max-width: 100%;
    margin: 0 5px;
}

.container.header.smaller {
    max-width: 940px;
}

.container.disabled {
    width: auto;
    max-width: 100%;
}

.container.disabled .content-inner {
    padding: 0;
}

.container.full {
    max-width: 100%;
}

.container.full-grid {
    padding: 0;
    max-width: calc(100% - 30px);
    margin: 0 15px;
}

.container.smaller {
    max-width: 970px;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.vh-100 {
    height: 100vh;
}

.vh-75 {
    height: 75vh;
}

.clearfix {
    display: block;
    content: "";
    clear: both;
}

.overflow-holder {
    overflow: hidden;
    margin-top: -25px;
    margin-bottom: -25px;
    padding-top: 25px;
    padding-bottom: 25px;
}

/* #endregion Section */

/*----------------------------------------------
4. Helper Class
----------------------------------------------*/

/* #region Helper Class */

.intro {
    margin-bottom: 50px;
}

.intro h1:first-child, .intro h2:first-child, .intro h3:first-child {
    margin-top: 0;
}

.intro .text-max-800 {
    margin-right: auto;
    margin-left: auto;
}

.blog-listing .items {
    margin-bottom: 30px;
}

.blog-listing .items .item {
    margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items {
    margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items .item {
    margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items .item.sticky .card:before {
    top: -10px;
    right: 0;
    bottom: 0;
    left: -10px;position: absolute;
    z-index: -1;
    content: '';
    width: calc(50% + 10px);
    height: calc(50% + 10px);
    border: none;
    background-color: var(--primary-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.4s;
    transition-property: opacity, transform;
}

.post-meta {
    margin: 35px 0 0;
}

.post-meta span {
    margin-right: 10px;
    display: inline-block;
    font-size: 1rem;
    color: var(--primary-color);
}

.post-meta i {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: inline-block;
    background: var(--primary-bg-color-3);
    border-radius: 100px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: var(--primary-color);
}

.post-holder {
    list-style: none;
    width: 100%;
    border-left: 5px solid var(--primary-bg-color-3);
    background-color: var(--primary-bg-color-2);
    padding: 25px;
    margin-top: 35px;
}

.posted-on a:not(.btn) {
    font-weight: 700;
}

.sidebar .item {
    margin-bottom: 50px;
}

.sidebar ol,
.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ol ol, .sidebar ul ul {
    padding: 15px;
}

.sidebar ul.children {
    padding: 0;
}

.sidebar .sub-menu {
    padding: 0;
}

.sidebar ul li:not(.nav-item):not(.list-group-item) {
    position: relative;
    padding-left: 0;
}

.sidebar ul ul li:not(.nav-item):not(.list-group-item) {
    padding-left: 20px;
}

.sidebar li:hover:before {
    background-color: var(--primary-color);
}

.sidebar ul li a:not(.btn) {
    color: inherit;
    overflow-wrap: break-word;
}

.sidebar ul li a:not(.btn):hover {
    color: var(--primary-color);
}

.sidebar .title {
    width: 100%;
    margin-top: 0;
    list-style: none;
}

.list-group .list-group-item .icon {
    color: var(--primary-color);
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlights.image-right .image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.highlights.image-left .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.highlights .items .item {
    margin-bottom: 35px;
}

.highlights .items .item:last-child {
    margin-bottom: 0;
}

.highlights p {
    white-space: pre-wrap;
}

.highlights .items .item .icon {
    color: var(--primary-color);
}

.offers .items .item {
    margin-bottom: 30px;
}

.offers .items .item .icon {
    margin: 0;
    color: var(--primary-color);
}

.offers .items .item .icon.featured {
    width: 65px;
    height: 65px;
    background: var(--primary-bg-color-3);
    border-radius: 100px;
    text-align: center;
    line-height: 65px;
    font-size: 32px;
}

.offers.secondary .items .item h4 {
    margin-top: 0;
}

.offers .card:hover .btn-icon {
    opacity: 1;
}

.images .image-over {
    margin-bottom: -1px;
    border-radius: 10px 10px 0 0;
}

.images .items .item .content {
    border-radius: 0 0 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.images .items .item .content p {
    margin: 1.5rem 0;
}

.team .items .item {
    margin-bottom: 50px;
}

.team .items h4 {
    margin: 0 0 10px;
}

.team .items p {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 500;
}

.team .share-list .nav-item {
    margin: 0;
}

.team .items .share-list .nav-link {
    padding: 0;
}

.team .items .share-list .nav-link:hover i {
    opacity: 1;
}

.team .quote {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: var(--primary-bg-color-2);
}

.team .quote h4 {
    margin-top: 0;
    color: var(--white-color);
}

.team .quote p {
    opacity: 0.85;
}

.team .quote h5 {
    margin-bottom: 0;
    font-family: var(--p-font);
    font-size: 1rem;
    color: var(--white-color);
    opacity: 0.85;
}

.team .quote .quote-left {
    position: absolute;
    top: 35px;
    left: 50px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.25);
}

.team .quote .quote-right {
    position: absolute;
    bottom: 35px;
    right: 50px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.25);
}

.team .quote .list-group-item {
    border-color: var(--secondary-l-color);
}

.team .quote .list-group .list-group-item .icon {
    color: var(--white-color);
}

.testimonials .card {
    padding: 35px 25px;
    background-color: var(--white-color);
}

.testimonials .card:not(.no-hover):hover {
    transform: none;
}

.odd.testimonials .card:not(.no-hover):hover p,
.odd.testimonials .card:not(.no-hover):hover i {
    color: inherit;
}

.testimonials .card .logo {
    width: auto;
    max-width: 100%;
    height: 25px;
    margin-right: auto;
    margin-bottom: 1.5rem;
    display: block;
}

.testimonials .card .quote-right {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.2);
}

.odd.testimonials .card .quote-right {
    color: rgba(255, 255, 255, 0.2);
}

.partners img {
    width: auto;
    max-width: 100%;
    height: 25px;
    opacity: 0.5;
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.partners img:hover {
    opacity: 1;
}

.odd.partners img {
    filter: brightness(0) invert(1);
}

.projects .btn-icon {
    color: var(--white-color);
    opacity: 0;
}

.projects .card:hover .btn-icon {
    opacity: 1;
}

.process .items {
    position: relative;
    cursor: crosshair;
}

.process .items:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 21px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: var(--primary-bg-color-3);
}

.process .step {
    position: relative;
    width: 45px;
    height: 45px;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 45px;
    color: var(--primary-color);
}

.process .step:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: var(--primary-bg-color-4);
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.process .step span {
    position: relative;
}

.process .items .item:hover .step span {
    color: var(--white-color);
}
.process .items .item:hover .step:before {
    background-color: var(--primary-color);
}

.counter.skills .items .item {
    padding: 35px 0 10px;
}

.counter .radial {
    position: relative;
    width: 110px;
    display: inline-block;
    text-align: center;
}

.counter .radial.left {
    width: auto;
    text-align: left;
}

.counter .radial canvas {
    height: 70px;
    vertical-align: middle;
    visibility: hidden;
}

.counter.skills .radial canvas {
    height: auto;
    visibility: visible;
}

.counter .radial span {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 2rem;
    font-weight: 700;
}

.counter.preloader .radial span {
    font-size: 2.5rem;
    color: var(--white-color);
}

.counter.funfacts .radial span {
    font-size: 2.5rem;
    color: var(--secondary-color);
}

.counter .radial span i {
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
}

.plans .items .item {
    margin-bottom: 30px;
}

.plans .items .item .card:hover .btn-icon {
    opacity: 1;
}

.plans .items .item .icon {
    color: var(--primary-color);
}

.plans .items .item .btn {
    margin-top: 25px;
}

.plans .items .item .list-group-item {
    padding: 10px 0;
    border: none;
}

.plans .items .item .card:hover {
    transform: translateY(-7.5px);
}

.plans .items .item .card:hover .btn {
    background: none;
    border: 1px solid var(--secondary-l-color);
}

.plans .items .item .card:hover .btn:after, .plans .items .item .card:hover .btn:before {
    content: none;
}

.plans .items .item .card:hover * {
    border-color: var(--secondary-l-color);
}

.plans .items .item h4 {
    margin: 1rem 0;
}

.plans .items .item .most-popular {
    padding: 60px 45px;
}

.plans .items .item .badge {
    position: absolute;
    top: 15px;
    left: 0;
    padding: 7.5px 15px 7.5px 10px;
    border-radius: 0 100px 100px 0px;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: var(--white-color);
    background: var(--secondary-color);
}

.plans .icon-min {
    font-size: 1rem;
    color: var(--primary-color);
}

.choose-plan {
    position: absolute;
    top: 25px;
    right: 25px;
}

.choose-plan .btn-icon {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.price {
    margin-bottom: 1rem;
    font-size: 42px;
    font-weight: 700;
    color: var(--primary-color);
}

.price i {
    margin: 0 5px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}

.price .plan {
    display: inline-block;
    margin: 0 0 0 5px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
}

.invert {
    filter: brightness(0) invert(1);
}

.grayscale {
    filter: grayscale(1);
}

.showcase.masonry .card {
    background-color: var(--white-color);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.showcase .items .item .card.no-image .image-over:before {
    background-color: var(--white-color);
}

.showcase.filter-section .card:hover .card-caption {
    opacity: 1;
}

.showcase .card {
    overflow: hidden;
    height: auto;
    background-color: transparent;
}

.showcase .card:not(.no-hover):hover {
    transform: none;
}

.showcase .card .image-over {
    overflow: hidden;
}

.showcase .card .image-over:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey-color);
    border-radius: 10px;
    opacity: 0.5;
    -webkit-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
}

.showcase .card .image-over img {
    position: relative;
    z-index: -1;
    height: auto;
    min-height: 360px;
    object-fit: cover;
    object-position: center;
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.showcase .card .card-body {
    padding: 50px 25px;
    transform: translateY(100px);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.showcase .card:hover .card-body {
    transform: translateY(0);
}

.showcase .card:hover .card-footer {
    transform: translateY(0);
    opacity: 1;
}

.showcase .card .card-body h4 {
    margin-bottom: 50px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.showcase .card:hover .card-body h4 {
    margin-bottom: 1.5rem;
}

.showcase .card .card-body p {
    margin: 1.5rem 0 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
}

.showcase .card:hover .card-body p {
    opacity: 1;
}

.showcase .card .card-footer .btn-icon {
    margin: 0;
}

.showcase .card .card-footer .btn-icon {
    margin: 0;
    color: var(--white-color);
    opacity: 1;
}

.showcase .card-caption {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.showcase .no-image .card-caption {
    position: relative;
}

.showcase .card-caption *, .showcase .card-caption *:not(.btn) {
    color: var(--primary-bg-color);
}

.showcase .no-image .card-caption *, .showcase .no-image .card-caption *:not(.btn) {
    color: var(--primary-t-color);
}

.showcase.odd .no-image .card-caption *, .showcase.odd .no-image .card-caption *:not(.btn) {
    color: var(--secondary-t-color);
}

.showcase .no-image .card-caption .card-body p {
    color: var(--primary-p-color);
}

.showcase.odd .no-image .card-caption .card-body p {
    color: var(--secondary-p-color);
}

.showcase .card-footer {
    position: absolute;
    top: 0;
    width: 100%;
    margin: auto;
    padding: 50px 25px;
    border: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    transform: translateY(-100px);
}

.showcase .card-footer a:not(.btn) {
    line-height: 1.2;
    text-align: center;
    color: var(--white-color);
}

.showcase .no-image .card-footer {
    border-color: rgba(0, 0, 0, 0.15);
}

.showcase .card:hover .image-over:before {
    opacity: 0.75;
}

.showcase .card:hover .image-over .mask-radius-full {
    opacity: 0;
}

.showcase .card:hover .image-over img {
    transform: scale(1.1);
    filter: blur(2px);
}

.showcase .card:hover .image-over .play-video-full {
    transform: translateY(-15%);
}

.filter-section .btn-group {
    margin: 0 0 35px;
    display: block;
}

.filter-section .btn-group .btn {
    display: inline-flex;
    align-items: center;
    margin: 0 10px;
    padding: 10px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--primary-p-color);
}

.odd.filter-section .btn-group .btn {
    color: var(--secondary-p-color);
}

.filter-section .btn-group .btn.focus {
    outline: none;
    box-shadow: none;
}

.filter-section .btn-group .btn:hover {
    color: var(--primary-t-color);
}

.odd.filter-section .btn-group .btn:hover {
    color: var(--white-color);
}

.filter-section .btn-group .btn.active {
    color: var(--primary-t-color);
}

.odd.filter-section .btn-group .btn.active {
    color: var(--white-color);
}

.filter-section .btn-group .btn.active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
    opacity: 0.15;
}

.filter-section .btn-group .btn i {
    margin: 0 10px 0;
}

.subscribe .items .item {
    padding: 0 10px;
}

.contacts h4 {
    margin-top: 0;
}

.contacts a:not(.btn) {
    padding: 5px 0;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--primary-color);
}

.contacts li {
    margin: 6.1px 0;
}

.contacts i {
    width: 45px;
    height: 45px;
    background: var(--primary-bg-color-3);
    border-radius: 100px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
}

.form .message {
    position: absolute;
    top: 0;
    left: 15px;
    width: 100%;
    height: 100%;
    padding: 35px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);
    border-radius: 5px;
    background-color: var(--card-bg-color);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.form .message .icon {
    margin: auto;
    color: var(--primary-color);
}

.form .message h3 {
    color: var(--primary-color);
}

.form .message .done {
    display: none;
}

.form .message.active {
    transform: scale(1);
}

.form-group-margin {
    margin: 0 -7.5px;
}

.widget-services .list-group .list-group-item .icon {
    width: 100px;
    height: 100px;
    margin-right: 25px;
    border-radius: 10px;
    background-color: var(--primary-bg-color-3);
}

.widget-services .list-group-item .h-img {
    width: auto;
    margin-right: 25px;
    padding: 7.5px;
}

.widget-author .items .item {
    margin-bottom: 0;
}

.widget-author .card {
    background-color: var(--primary-bg-color-3);
}

.widget-author .card:hover {
    transform: initial;
}

.widget-author .person {
    width: 128px;
    height: 128px;
    border-radius: 100%;
}

/* #endregion Helper Class */

/*----------------------------------------------
5. Header
----------------------------------------------*/

/* #region Header */

header {
    position: relative;
    z-index: 5;
}

.navbar-holder {
    position: relative;
    z-index: -100;
    min-height: auto;
    pointer-events: none;
    background-color: var(--nav-holder-bg-color);
}

.navbar {
    -webkit-transition: all .15s ease-out 0s!important;
    -o-transition: all .15s ease-out 0s!important;
    -moz-transition: all .15s ease-out 0s!important;
    transition: all .15s ease-out 0s!important;
}

.navbar.top {
    position: relative;
    z-index: 5;
    height: 50px;
    padding: 0;
    background-color: var(--nav-top-bg-color);
}

.navbar.sub {
    top: 50px;
}

header .navbar-sticky {
    -webkit-transition: all .15s ease-out 0s!important;
    -o-transition: all .15s ease-out 0s!important;
    -moz-transition: all .15s ease-out 0s!important;
    transition: all .15s ease-out 0s!important;
    /* ! important is necessary to avoid conflict with the AOS animate. */
}

header .navbar-sticky.sub.hidden {
    -webkit-transform: translate3d(0, -101%, 0)!important;
    -ms-transform: translate3d(0,-101%, 0)!important;
    -moz-transform: translate3d(0, -101%, 0)!important;
    transform: translate3d(0, -101%, 0)!important;
    /* ! important is necessary to avoid conflict with the AOS animate. */
}

header .navbar-sticky.sub.visible {
    -webkit-transform: translate3d(0,0,0)!important;
    -ms-transform: translate3d(0,0,0)!important;
    -moz-transform: translate3d(0,0,0)!important;
    transform: translate3d(0,0,0)!important;
    /* ! important is necessary to avoid conflict with the AOS animate. */
}

header .navbar-sticky.sub {
    top: 0;
    padding: 10px 25px;
    background-color: var(--header-bg-color);
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    -webkit-transform: translate3d(0, -101%, 0)!important;
    -ms-transform: translate3d(0,-101%, 0)!important;
    -moz-transform: translate3d(0, -101%, 0)!important;
    transform: translate3d(0, -101%, 0)!important;
    /* ! important is necessary to avoid conflict with the AOS animate. */
}

header .navbar-expand {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    padding: 20px 25px;
}

header .navbar-expand.relative {
    position: relative;
}

header .navbar-expand .navbar-nav {
    display: inline-block;
}

header .navbar-expand .navbar-nav.toggle {
    display: none;
}

header .navbar-expand .navbar-nav.toggle i {
    font-size: 20px;
}

header .navbar-expand .navbar-brand {
    padding: 0;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    color: var(--secondary-p-color);
}

header .navbar-expand .navbar-brand.light {
    color: var(--nav-item-color);
}

header .navbar-expand .navbar-brand i {
    color: var(--primary-color);
}

header .navbar-expand .navbar-brand img {
    height: var(--nav-brand-height);
}

header .navbar-expand .navbar-brand .brand {
    letter-spacing: 5px;
}

header .navbar-expand .navbar-brand .featured {
    position: relative;
    display: inline-block;
    padding: 13px 2px 14px 30px;
}

header .navbar-expand .navbar-brand .featured:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 7.5px);
    height: 100%;
    border-radius: 100px 0 0 100px;
    background-color: var(--secondary-color);
    opacity: 0.15;
}

header .navbar-expand .navbar-brand .featured .first {
    position: relative;
    margin-right: -10px;
    color: var(--secondary-color);
}

.navbar-expand .navbar-nav .nav-item {
    margin: 0;
    display: inline-flex;
}

.navbar-expand .navbar-nav ul .nav-item {
    display: block;
}

.navbar-expand.top .navbar-nav .nav-item a:not(.btn) {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--secondary-p-color);
}

.navbar-expand.top .navbar-nav .nav-item a:not(.btn):hover {
    color: var(--secondary-color);
}

.navbar-expand .navbar-nav .nav-item a:not(.btn) {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}

.navbar-expand .navbar-nav .secondary-button:not(.btn) {
    padding-right: 1rem;
    padding-left: 1rem;
    color: var(--nav-item-color);
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.navbar-expand .navbar-nav.items .secondary-button:not(.btn):not(.title):hover,
.navbar-expand .navbar-nav.icons .secondary-button:not(.btn):not(.title):hover {
    color: var(--secondary-color);
}

.navbar-expand .navbar-nav.items .secondary-button.active:not(.btn):not(.title) {
    color: var(--secondary-color);
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn) {
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.35rem 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--primary-t-color);
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn) span {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    display: inline-block;
    border-radius: 100px;
    background-color: var(--primary-bg-color-3);
    line-height: 24px;
    text-align: center;
    font-weight: 500;
    color: var(--primary-color);
}

.odd .navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn) {
    color: var(--secondary-t-color);
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button.title {
    font-weight: 700;
    color: var(--primary-t-color);
    opacity: 0.8;
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn):not(.title):before,
.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn):not(.title):after {
    content: none;
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn):not(.title).active,
.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn):not(.title):hover {
    background-color: var(--primary-bg-color-3);
    color: var(--primary-color);
}

.navbar-expand .navbar-nav .dropdown-menu .secondary-button:not(.btn):not(.title):hover span {
    background-color: var(--primary-bg-color);
}

.navbar-nav i.icon-arrow-down {
    position: relative;
    top: 1.5px;
    margin: 0 0 0 0.3rem;
    font-size: 12px;
    font-weight: 700;
}

.navbar-nav i.icon-arrow-right {
    position: relative;
    left: 5px;
    top: 0;
    margin: 0 0 0 0.3rem;
    font-size: 10px;
    font-weight: 700;
}

.navbar-expand .icons i {
    font-weight: 400;
}

.dropdown .dropdown-menu {
    background-color: var(--primary-bg-color);
    width: max-content;
    min-width: 120px;
    display: block;
    visibility: hidden;
    opacity: 0;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.odd .dropdown .dropdown-menu {
    background-color: var(--secondary-bg-color-2);
}

.dropdown:hover > .dropdown-menu {
    visibility: unset;
    opacity: 1;
    margin: 0;
    transition-timing-function: ease;
    transition-duration: .4s;
    transition-property: opacity, transform;
}

.dropdown-menu .dropdown-menu {
    position: absolute;
    top: -8px;
    left: 100%;
    border-radius: 0;
}

.dropdown-item.active, .dropdown-item:hover, .dropdown-item:active {
    color: inherit;
    text-decoration: none;
    background-color: inherit;
    box-shadow: none;
    outline: none;
}

.menu .items {
    margin-left: -5px;
    width: calc(100% + 10px);
}

.menu .navbar-nav {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
}

.menu .nav-item {
    margin: 0;
}

.menu .dropdown .dropdown-menu {
    display: none;
    width: 100%;
    visibility: visible;
    opacity: 1;
    background: none;
    box-shadow: none;
    border: none;
    text-align: center;
    padding: 0 0 0 20px;
    margin: 0 0 15px;
    list-style: none;
    color: var(--secondary-p-color);
}

.menu .dropdown .dropdown-menu.show {
    display: block;
}

.menu .dropdown-menu .dropdown-menu {
    position: relative;
    top: initial;
    left: initial;
}

.menu .dropdown .dropdown-menu .left,
.menu .dropdown .dropdown-menu .right {
    width: 50%;
}

.menu .secondary-button:not(.btn) {
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid var(--primary-l-color);
    color: var(--primary-p-color);
    display: inline-block;
    width: calc(100% - 12px);
    vertical-align: top;
    padding: 10px 5px;
    margin: 0 5px;
    border-radius: 4px;
}

.odd .menu .secondary-button:not(.btn) {
    border: 1px solid var(--secondary-l-color);
    color: var(--secondary-p-color);
}

.menu .secondary-button:not(.btn):hover,
.odd .menu .secondary-button:not(.btn):hover {
    color: var(--primary-color);
}

.menu .dropdown .dropdown-menu .secondary-button.title {
    font-weight: 700;
    color: var(--primary-t-color);
    border: none;
    opacity: 0.8;
}

.odd .menu .dropdown .dropdown-menu .secondary-button.title {
    color: var(--secondary-t-color);
}

.menu .dropdown .dropdown-menu .nav-item .secondary-button {
    font-size: 1rem;
    font-weight: 400;
    background: none;
    color: var(--primary-p-color);
    display: flex;
    justify-content: space-between;
    width: calc(100% - 12px);
    vertical-align: top;
    padding: 0;
    margin: 0 5px 10px;
    border-radius: 4px;
    text-align: left;
}

.menu .dropdown .dropdown-menu .nav-item .secondary-button span {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 100px;
    line-height: 18px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}

.odd .menu .dropdown .dropdown-menu .nav-item .secondary-button {
    color: var(--secondary-p-color);
}

.menu .dropdown .dropdown-menu .secondary-button:hover,
.odd .menu .dropdown .dropdown-menu .secondary-button:hover {
    color: var(--primary-color);
}

.menu .dropdown:first-child {
    margin-top: 0;
}

.menu .secondary-button:first-child {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    border: none;
    color: var(--primary-t-color);
}

.odd .menu .secondary-button:first-child {
    margin-bottom: 0;
    font-weight: 700;
    border: none;
    color: var(--secondary-t-color);
}

.menu .dropdown-menu .dropdown > .secondary-button:first-child {
    font-size: 1rem;
    font-weight: 700;
}

.menu .navbar-nav i.icon-arrow-down {
    font-size: 18px;
}

/* #endregion Header */

/*----------------------------------------------
6. Slider
----------------------------------------------*/

/* #region Slider */

.slider-h-auto {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.slider-h-auto .swiper-wrapper,
.slider-h-auto .swiper-slide {
    min-height: 75vh;
}

.slider-h-auto.no-slider .inner {
    top: 0;
    padding-top: 100px;
    padding-bottom: 100px;
}

.slider-h-100 {
    width: 100%;
    height: calc(100vh - 50px);
}

.slider-h-100.slider-h-auto {
    min-height: calc(100vh - 50px);
}

.slider-h-85 {
    width: 100%;
    height: 85vh;
}

.slider-h-75 {
    width: 100%;
    height: 75vh;
}

.slider-h-75.slider-h-auto {
    min-height: 75vh;
}

.slider-h-70 {
    width: 100%;
    height: 70vh;
}

.slider-h-65 {
    width: 100%;
    height: 65vh;
}

.slider-h-60 {
    width: 100%;
    height: 60vh;
}

.slider-h-50 {
    width: 100%;
    height: 50vh;
}

.slider-h-50.slider-h-auto {
    min-height: 50vh;
}

.slider-h-25 {
    width: 100%;
    height: 25vh;
}

.slider-h-25.slider-h-auto {
    min-height: 25vh;
}

.hero .parallax-y-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.hero .parallax-x-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.hero.slider-parallax {
    position: fixed;
    top: 0;
    min-height: auto;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.slider-parallax-holder {
    position: relative;
    z-index: -100;
    min-height: 100vh;
    pointer-events: none;
}

.mid-slider {
    overflow: hidden;
    padding: 0 15px;
}

.mid-slider-simple {
    padding: 0 15px;
}

.mid-slider-simple .card:not(.no-hover):hover {
    transform: initial;
}

.full-slider {
    cursor: w-resize;
}

.full-slider .inner,
.no-slider .inner {
    top: 0;
    max-width: 1140px;
    padding: 0;
    margin: auto;
}

.no-slider.slider-h-75 .inner {
    top: 10px;
}

.full-slider .inner .center,
.no-slider .inner .center {
    width: 100%;
    padding: 3rem 0;
}

.full-slider .inner .left,
.no-slider .inner .left {
    width: 60%;
    padding: 3rem 0;
}

.full-slider .inner .right,
.no-slider .inner .right {
    width: 45%;
    padding: 3rem 0;
}

.full-slider.featured .inner .left:before,
.no-slider.featured .inner .left:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 5%;
    right: 0;
    bottom: 0;
    left: 5%;
    width: 45%;
    height: 95%;
    border-top: 4px solid;
    border-right: 4px solid;
    transform: rotate(-15deg);
    border-image: -webkit-linear-gradient(to right, transparent 55%, var(--primary-color) 55%, var(--primary-color) 100%) 30;
    border-image: linear-gradient(to right, transparent 55%, var(--primary-color) 55%, var(--primary-color) 100%) 30;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
    opacity: 0;
}

.full-slider.featured .inner .left:after,
.no-slider.featured .inner .left:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5%;
    right: 0;
    bottom: 0;
    left: 5%;
    width: 45%;
    height: 95%;
    border-left: 4px solid;
    border-bottom: 4px solid;
    transform: rotate(-15deg);
    border-image: -webkit-linear-gradient(to bottom, transparent 83%, var(--primary-color) 83%, var(--primary-color) 100%) 30;
    border-image: linear-gradient(to bottom, transparent 83%, var(--primary-color) 83%, var(--primary-color) 100%) 30;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
    opacity: 0;
}

.full-slider.featured .inner .right:before,
.no-slider.featured .inner .right:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 0;
    right: 5%;
    width: 45%;
    height: 95%;
    border-top: 4px solid;
    border-right: 4px solid;
    transform: rotate(-15deg);
    border-image: -webkit-linear-gradient(to right, transparent 55%, var(--primary-color) 55%, var(--primary-color) 100%) 30;
    border-image: linear-gradient(to right, transparent 55%, var(--primary-color) 55%, var(--primary-color) 100%) 30;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
    opacity: 0;
}

.full-slider.featured .inner .right:after,
.no-slider.featured .inner .right:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5%;
    right: 5%;
    bottom: 0;
    width: 45%;
    height: 95%;
    border-left: 4px solid;
    border-bottom: 4px solid;
    transform: rotate(-15deg);
    border-image: -webkit-linear-gradient(to bottom, transparent 83%, var(--primary-color) 83%, var(--primary-color) 100%) 30;
    border-image: linear-gradient(to bottom, transparent 83%, var(--primary-color) 83%, var(--primary-color) 100%) 30;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
    opacity: 0;
}

.full-slider .inner .left.off:before,
.full-slider .inner .left.off:after,
.full-slider .inner .right.off:before,
.full-slider .inner .right.off:after {
    content: none;
}

.full-slider .inner .left.init:before,
.full-slider .inner .left.init:after,
.no-slider .inner .left.init:before,
.no-slider .inner .left.init:after,
.full-slider .inner .right.init:before,
.full-slider .inner .right.init:after,
.no-slider .inner .right.init:before,
.no-slider .inner .right.init:after {
    opacity: 1;
}

.full-slider.featured .inner .left .title,
.no-slider.featured .inner .left .title,
.full-slider.featured .inner .right .title,
.no-slider.featured .inner .right .title {
    max-width: 85%;
}

.full-slider .slide-content .center .description,
.no-slider .slide-content .center .description {
    max-width: 800px;
    font-size: 1.2rem;
}

.full-slider .slide-content .center .description.smaller,
.no-slider .slide-content .center .description.smaller {
    max-width: 500px;
}

.full-slider .slide-content .left .description,
.no-slider .slide-content .left .description,
.full-slider .slide-content .right .description,
.no-slider .slide-content .right .description {
    max-width: 550px;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
}

.full-slider.featured .inner .right .description {
    right: 15%;
    position: relative;
}

.full-slider .slide-content .description.bigger,
.no-slider .slide-content .description.bigger {
    max-width: 100%;
    color: var(--primary-t-color);
    font-size: 1.5rem;
    font-weight: 400;
}

.odd .full-slider .slide-content .description.bigger,
.odd .no-slider .slide-content .description.bigger {
    color: var(--secondary-p-color)
}

.full-slider .swiper-button-next {
    right: 1.5rem;
}

.full-slider .swiper-button-prev {
    left: 1.5rem;
}

.full-slider .swiper-button-next:focus,
.full-slider .swiper-button-prev:focus {
    outline: none;
}

.full-slider .swiper-button-next,
.full-slider .swiper-button-prev {
    top: calc(50% + 60px);
    background-image: -webkit-linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-image: linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    opacity: 0.25;
    padding: 2rem 1.5rem;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

.full-slider .swiper-button-next:hover,
.full-slider .swiper-button-prev:hover {
    background-image: -webkit-linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-image: linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
}

.full-slider .swiper-button-next:after,
.full-slider .swiper-button-prev:after {
    font-size: 3rem;
}

.mid-slider {
    cursor: w-resize;
}

.min-slider {
    cursor: w-resize;
}

.slide-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.slide-content {
    position: relative;
    z-index: 1;
    width: 100%;
}

.swiper-slide .aos-animate {
    display: none;
}

.swiper-slide.swiper-slide-active .aos-animate {
    display: block;
}

.swiper-slide .fit-image.aos-init {
    opacity: 1;
}

.swiper-slide .fit-image.aos-animate {
    visibility: hidden;
    display: block;
}

.swiper-slide.swiper-slide-active .fit-image.aos-animate {
    visibility: visible;
    display: block;
    transform: translateZ(0) scale(1.2);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 3s;
    transition-property: opacity, transform;
}

.full-slider.swiper-container-horizontal > .swiper-pagination-bullets {
    position: absolute;
    top: calc(50% - 50px);
    right: 40px;
    left: initial;
    width: fit-content;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 35px;
    margin: 7.5px 0!important;
    display: block;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    opacity: 0.5;
    outline: none;
    /* ! important is necessary to avoid conflict with the Swipper Slider. */
}

.swiper-pagination-bullet:hover {
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: var(--primary-color);
    opacity: 1;
}

.swiper-pagination-bullet-active:hover {
    background-color: var(--primary-color);
}

.pagination {
    width: fit-content;
    margin: auto;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.pagination .page-item {
    margin: 0;
}

.pagination .page-item:focus {
    outline: none;
    box-shadow: none;
}

.pagination .page-item .page-link {
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 400;
    background-color: var(--primary-bg-color);
    border-radius: 2px;
    border: 1px solid;
    border-color: var(--primary-bg-color-2);
    color: var(--primary-t-color);
}

.odd .pagination .page-item .page-link {
    background-color: var(--secondary-bg-color);
    border-color: var(--secondary-bg-color-2);
}

.pagination .page-item.active .page-link {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
}

.odd .pagination .page-item.active .page-link {
    background-color: var(--secondary-bg-color);
}

.pagination .page-item .page-link:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--primary-bg-color);
}

.odd .pagination .page-item .page-link:hover {
    color: var(--secondary-bg-color);
}

.pagination .page-item .page-link:focus {
    outline: none;
    box-shadow: none;
}

/* #endregion Slider */

/*----------------------------------------------
7. Feature
----------------------------------------------*/

/* #region Feature */

.preloader {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.4s;
    transition-property: opacity, transform;
}

.preloader.ready {
    z-index: -100;
    opacity: 0;
}

.icon  {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 44px;
    display: block;
    margin: auto;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.icon.smaller {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
}

.icon.bigger {
    width: 5rem;
    height: 5rem;
    margin: 0;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: bold;
}

.icon.circle {
    border-radius: 100px;
    font-size: 28px;
}

.bricklayer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.bricklayer-column-sizer {
    width: 33.3333%;
    display: none;
}

.columns-1 .bricklayer-column-sizer {
    width: 100%;
}

.columns-2 .bricklayer-column-sizer {
    width: 50%;
}

.columns-3 .bricklayer-column-sizer {
    width: 33.3333%;
}

.columns-4 .bricklayer-column-sizer {
    width: 25%;
}

.columns-5 .bricklayer-column-sizer {
    width: 20%;
}

.columns-6 .bricklayer-column-sizer {
    width: 16.6666%;
}

.bricklayer-column {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 15px;
}

.bricklayer .item {
    margin: 0 0 30px 0;
}

.card-columns {
    margin: 0 15px;
    column-gap: 30px;
}

.card-columns .card {
    margin-bottom: 30px;
}

.card {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 45px;
    border-radius: 10px;
    border: none;
    background-color: var(--card-bg-color);
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.card.no-hover {
    background-color: transparent;
    box-shadow: none;
}

.odd:not(.custom) .card:not(.no-hover) {
    background-color: rgba(255, 255, 255, 0.035);
}

.card p {
    margin: 0 0 10px;
    white-space: pre-wrap;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.card-img-top {
    border-radius: 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 35px 30px;
}

.card-body h3, .card-body h4, .card-body h5, .card-body h6 {
    margin-top: 0;
}

.card-footer {
    border-radius: 0;
    background: none;
    padding: 30px 0;
    margin: 0 30px;
    border-top: 1px solid var(--primary-l-color);
}

.card-footer a {
    margin: 0 10px;
}

.card-footer a:not(.btn) {
    line-height: 1.2;
    text-align: left;
    color: var(--primary-p-color);
}

.card-footer i {
    margin: 0 10px 2.5px 0;
}

.card.simple {
    box-shadow: none;
    background: none;
    padding: 0 2rem;
}

.card.simple:hover {
    background: none;
}

.card:not(.no-hover):hover {
    transform: translateY(-7.5px);
}

.sticky.card:not(.no-hover) {
    background: -webkit-linear-gradient(45deg, var(--secondary-color) 15%, var(--primary-color) 65%);
    background: linear-gradient(45deg, var(--secondary-color) 15%, var(--primary-color) 65%);
}

.masonry .card:not(.no-hover):hover {
    transform: translateY(0);
}

.card:not(.no-hover):hover:before,
.card:not(.no-hover):hover:after {
    opacity: 0;
}

.card:hover .image-person img {
    transform: scale(1.1);
}

.odd .card:not(.no-hover):hover h3,
.odd .card:not(.no-hover):hover h4,
.odd .card:not(.no-hover):hover p {
    color: var(--primary-bg-color);
}

.odd .card:not(.no-hover):hover span,
.odd .card.stycky:not(.no-hover) span {
    color: var(--primary-bg-color);
}

.odd .card:not(.no-hover):hover a,
.odd .card.sticky:not(.no-hover) a {
    color: var(--primary-bg-color);
}

.odd .card:not(.no-hover):hover i,
.odd .card.sticky:not(.no-hover) i {
    color: var(--primary-bg-color);
}

.odd .card:not(.no-hover):hover .icon,
.card.sticky:not(.no-hover) .icon {
    background-color: transparent;
    color: var(--primary-bg-color);
}

.odd.features .card:not(.no-hover):hover .icon {
    background-color: var(--primary-color);
}

.odd .card:hover .card-footer,
.odd .card.sticky .card-footer {
    color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.25);
}

.odd .card:hover .btn-icon {
    opacity: 1;
    color: var(--primary-bg-color);
}

.odd .card:hover .share-list i {
    color: var(--primary-bg-color);
}

.showcase .card .card-body .badges {
    transform: translateY(100px);
}

.showcase .card:hover .card-body .badges {
    transform: translateY(0);
}

.card .badges a:first-child .badge {
    margin-bottom: 15px;
}

.card .badges .badge {
    width: 100%;
    margin: 0;
}

.badges {
    display: inline-block;
}

.badges .badge {
    margin: 0 10px 12.5px 0;
    -webkit-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
}

.badges .badge a:not(.btn) {
    line-height: 1;
}

.badges .badge:hover {
    color: var(--white-color);
    background-color: var(--secondary-color);
}

.badges .badge a:not(.btn):hover {
    color: var(--white-color);
}

.badge {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-color);
    background-color: var(--primary-bg-color-3);
}

.badge.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    background-color: var(--primary-bg-color-3);
}

.badge.tag {
    margin: 0.3rem;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-p-color);
    border: 1px solid var(--primary-l-color);
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.badge.tag:hover,
.badge.tag.active {
    color: var(--white-color);
    background-color: var(--primary-color);
}

.breadcrumb {
    margin: 0;
    background: none;
    justify-content: center;
    color: inherit;
}

.breadcrumb-item {
    font-size: 1rem;
    font-weight: 400;
}

.breadcrumb-item a:not(.btn) {
    color: inherit;
}

.breadcrumb-item a:hover {
    color: var(--primary-color);
}

.breadcrumb-item+.breadcrumb-item::before {
    font-weight: 400;
}

.breadcrumb-item.active {
    color: var(--secondary-color);
}

.breadcrumb-item.active:hover {
    color: var(--secondary-color);
}

.share-list {
    margin: 0.5rem 0 0 0;
    display: -webkit-inline-box;
}

.sidebar .share-list {
    margin: 0;
}

.share-list .nav-link i {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: inline-block;
    background: var(--primary-bg-color-3);
    border-radius: 100px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: var(--primary-color);
    -webkit-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
}

.share-list .nav-link i:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.share-list li:first-child i {
    margin-left: 0;
}

.share-list .nav-item {
    margin: 0;
}

.share-list .nav-link {
    padding: 0;
}

.image-over {
    overflow: hidden;
    border-radius: 10px;
}

.image-person img {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.4s;
}

.person {
    width: 100%;
    border-radius: 5px;
}

.square-image {
    max-width: 900px;
    margin: auto;
    border-radius: 4px;
    background: var(--primary-color);
}

.square-image .icon {
    position: absolute;
    z-index: 1;
    color: var(--white-color);
    opacity: 0.75;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.3s;
    transition-property: opacity, transform;
}

.square-image .icon.clone {
    margin: -17.5px -10px 0;
    color: var(--secondary-color);
    opacity: 0.75;
}

.square-image img {
    opacity: 0.85;
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.3s;
    transition-property: opacity, transform;
}

.square-image:hover img {
    opacity: 0.75;
    filter: none;
}

.square-image:hover .icon {
    transform: scale(1.1);
    opacity: 1;
}

.mask-radius {
    content: '';
    position: absolute;
    z-index: 1;
    right: 15px;
    bottom: 0;
    width: 58%;
    height: 58%;
    background-image: -webkit-linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-image: linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    border-radius: 100% 0 0 0;
    opacity: 0.25;
}

.mask-radius-full {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-image: linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    opacity: 0.5;
}

.play-video {
    position: absolute;
    z-index: 2;
    right: 15%;
    bottom: 15%;
    padding: 1.75rem 1.5rem 1.75rem 2rem;
    border-radius: 100%;
    font-size: 3rem;
    background-image: -webkit-linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-image: linear-gradient(45deg, var(--secondary-color) 0%, var(--primary-color) 55%);
    background-size: 200% auto;
    color: var(--primary-b-color);
}

.play-video-full {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 4rem;
    color: var(--primary-b-color);
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.full-image[data-mask="90"] {
    opacity: 0.1;
}

.full-image[data-mask="80"] {
    opacity: 0.2;
}

.full-image[data-mask="70"] {
    opacity: 0.3;
}

.full-image[data-mask="60"] {
    opacity: 0.4;
}

.full-image[data-mask="50"] {
    opacity: 0.5;
}

.full-image[data-mask="40"] {
    opacity: 0.6;
}

.full-image[data-mask="30"] {
    opacity: 0.7;
}

.full-image[data-mask="20"] {
    opacity: 0.8;
}

.full-image[data-mask="10"] {
    opacity: 0.9;
}

.full-image[data-mask="0"] {
    opacity: 1;
}

.full-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.full-image.to-bottom {
    object-position: bottom;
}

.hero-image {
    position: absolute;
    right: 10%;
    bottom: 0;
    width: auto;
    height: 85%;
}

.hero-image-left {
    position: absolute;
    left: 10%;
    bottom: 0;
    width: auto;
    height: 85%;
}

.fit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fit-image.w-85 {
    width: 85%;
}

.fit-image.top {
    object-position: top;
}

.fit-image.bottom {
    object-position: bottom;
}

.btn {
    width: fit-content;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .5rem 1.25rem;
    border-width: 0;
    border-radius: 2px;
}

.btn.smaller, .btn.smaller:active, .btn.smaller:focus {
    width: fit-content;
    box-sizing: content-box;
    margin: 0 5px;
    padding: 7.5px 12.5px;
    display: inline-block;
}

.btn.w-100 {
    max-width: 100%;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn:hover, .btn:focus {
    outline: none;
    box-shadow: none;
}

.btn i {
    margin-right: 0.5rem;
}

.btn i.left {
    margin-right: 0;
    margin-left: 0.5rem;
}

.btn-icon {
    position: absolute;
    top: 43px;
    right: 45px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    color: var(--primary-color);
    opacity: 0.5;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.card:hover .btn-icon.pulse:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    animation: pulse 1.5s infinite;
    -webkit-animation: pulse 1.5s infinite;
}

/* #endregion Feature */

/*----------------------------------------------
8. Form and Field
----------------------------------------------*/

/* #region Form and Field */

.grecaptcha-badge {
    visibility: hidden;
    z-index: -100;
}

.form-control {
    width: 100%;
    height: 50px;
    background: no-repeat;
    box-shadow: none;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.075);
    border: none;
    border-radius: 4px;
    line-height: 1.2;
    color: var(--primary-t-color);
}

.form-control.less-opacity {
    background-color: rgba(0, 0, 0, 0.75);
}

.odd .form-control {
    background-color: rgba(255, 255, 255, 0.05);
    color: var(--secondary-t-color);
}

.odd .form-control.less-opacity {
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--primary-t-color);
}

select.form-control {
    color: #6c757d;
}

.odd select.form-control {
    color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
    background-color: inherit;
    opacity: 0.75;
    cursor: no-drop;
}

.form-control:focus {
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
    color: var(--primary-t-color);
    border: none;
    box-shadow: none;
}

.form-control.less-opacity:focus {
    background-color: rgba(0, 0, 0, 0.9);
}

.odd .form-control:focus {
    background-color: rgba(255, 255, 255, 0.075);
    outline: none;
    color: var(--secondary-t-color);
    border: none;
    box-shadow: none;
}

.odd .form-control.less-opacity:focus {
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--primary-t-color);
}

.form-control.valid {
    background-color: rgba(0, 122, 77, 0.25);
}

.form-control.less-opacity.valid {
    background-color: rgba(0, 122, 77, 0.5);
    color: var(--white-color);
}

.form-control.invalid {
    background-color: rgba(227, 79, 79, 0.25);
}

.form-control.less-opacity.invalid {
    background-color: rgba(227, 79, 79, 0.5);
    color: var(--white-color);
}

textarea.form-control {
    width: 100%;
    min-height: 152px;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
    border-radius: 2px;
}

.input-group {
    margin: 10px 0;
}

.input-group .icon-arrow-down {
    position: absolute;
    top: calc(50% - 8px);
    right: 8px;
    color: var(--primary-t-color);
}

.odd .input-group .icon-arrow-down {
    color: var(--secondary-l-color);
}

button:disabled {
    cursor: no-drop;
}

.form-alert {
    margin: 1rem 0;
    display: block;
    color: var(--invalid-color);
}

.form-alert.valid {
    color: var(--valid-color);
}

.form-alert.invalid {
    color: var(--invalid-color);
}

/* #endregion Form and Field */

/*----------------------------------------------
8.1 Multi-Step Form
----------------------------------------------*/

/* #region Multi-Step Form */

.multi-step-form {
    min-height: 575px;
}

.multi-step-form fieldset {
    width: 100%;
    position: relative;
}

.multi-step-form fieldset:not(:first-of-type) {
    display: none;
}

.multi-step-form input {
    width: 100%;
}

.multi-step-form .mask-radius {
    width: calc(58% + 15px);
    right: 0;
}

.multi-step-form .step-images {
    min-height: 575px;
}

.multi-step-form .content-images .step-image .fit-image {
    min-height: 575px;
}

.progressbar {
    margin-bottom: 25px;
    padding: 0;
    overflow: hidden;
    counter-reset: step;
}

.progressbar:after {
    content: '';
    width: 100%;
    height: 6px;
    background-color: var(--primary-bg-color-3);
    position: absolute;
    right: 0;
    top: 25px;
    z-index: 0;
}

.odd .progressbar:after {
    background-color: var(--secondary-bg-color-3);
}

.progressbar.complete:after {
    background: linear-gradient(to right, var(--primary-color) 20%, var(--secondary-color) 40%, var(--secondary-color) 60%, var(--primary-color) 80%);
    background-size: 200% auto;
}

.progressbar li.active:before, .progressbar li.active:after {
    background: linear-gradient(to right, var(--primary-color) 20%, var(--secondary-color) 40%, var(--secondary-color) 60%, var(--primary-color) 80%);
    background-size: 200% auto;
    -webkit-animation: effect 1s linear infinite;
    animation: effect 1s linear infinite;
    color: var(--primary-bg-color);
}

.odd .progressbar li.active:before, .progressbar li.active:after {
    color: var(--secondary-bg-color);
}

.progressbar li {
    list-style-type: none;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    width: 33.3333%;
    float: left;
    position: relative;
    color: var(--primary-t-color);
}

form[data-steps="1"] .progressbar {
    display: none;
}

form[data-steps="1"] .progressbar li {
    width: 100%;
}

form[data-steps="2"] .progressbar li {
    width: 50%;
}

form[data-steps="3"] .progressbar li {
    width: 33.3333%;
}

.odd .progressbar li {
    color: var(--secondary-t-color);
}

.progressbar li:before {
    position: relative;
    z-index: 2;
    content: counter(step);
    counter-increment: step;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    color: var(--primary-t-color);
    background-color: var(--primary-bg-color-3);
    border-radius: 100px;
    margin: 0 auto 10px auto;
}

.odd .progressbar li:before {
    color: var(--secondary-t-color);
    background-color: var(--secondary-bg-color-3);
}

.progressbar li:after {
    content: '';
    width: 100%;
    height: 6px;
    background-color: var(--primary-bg-color-3);
    position: absolute;
    left: -50%;
    top: 17px;
    z-index: 1;
}

.odd .progressbar li:after {
    background-color: var(--secondary-bg-color-3);
}

/* #endregion Multi-Step Form */

/*----------------------------------------------
9. List
----------------------------------------------*/

/* #region List */

.list-group-item {
    margin: 0;
    background: none;
    font-size: 1rem;
    padding-right: 0;
    padding-left: 0;
    border-color: var(--primary-l-color);
}

.odd .list-group-item {
    border-color: var(--secondary-l-color);
}

.list-group-item a:hover {
    color: var(--primary-color);
}

.list-group-item img {
    width: 65px;
    height: 65px;
    display: block;
    margin-right: 50px;
    border-radius: 4px;
    background-color: var(--primary-bg-color-3);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.odd .list-group-item img {
    background-color: var(--secondary-bg-color-3);
}

.list-group-item a:not(.btn) {
    color: inherit;
}

.list-group-item .icon {
    font-size: 34px;
    text-align: left;
}

.list-group-item:last-child .list-group-content {
    padding-bottom: 0;
}

.list-group-content {
    padding: 10px 0;
}

.list-group-content h4 {
    margin: 0 0 5px;
}

.list-group-content p {
    margin: 0;
}

/* #endregion List */

/*----------------------------------------------
10. Gallery
----------------------------------------------*/

/* #region Gallery */

.gallery {
    position: relative;
    overflow: hidden;
}

.content .gallery {
    margin: 35px 0 0 0;
}

.gallery .item:not(:last-child) {
    margin-bottom: 30px;
}

.gallery img {
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.gallery .fit-image {
    width: 100%;
    height: 100%;
}

.gallery img:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.gallery a:hover img {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.gallery.featured {
    transform: scale(1);
}

.gallery.featured a:first-child:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -30px;
    left: -15px;
    width: 90%;
    height: 90%;
    border: 4px solid;
    border-color: var(--primary-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
}

.gallery.featured a:last-child:before {
    content: '';
    position: absolute;
    z-index: -1;
    right: -15px;
    bottom: 0;
    width: 90%;
    height: 90%;
    border: 4px solid;
    border-color: var(--primary-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
}

/* #endregion Gallery */

/*----------------------------------------------
11. Footer
----------------------------------------------*/

/* #region Footer */

footer {
    position: relative;
    background-color: var(--footer-bg-color);
}

footer.odd {
    background-color: #111111;
}

footer .card h4 {
    margin-top: 0;
}

footer .card i {
    margin-right: 10px;
}

footer .items .card a:not(.btn) {
    margin: 0 0 0.5rem;
    display: block;
    color: var(--primary-p-color);
}

footer .odd .items .card a:not(.btn):last-child {
    margin-bottom: 5px;
}

footer .odd .items .card a:not(.btn) {
    color: var(--secondary-p-color);
}

footer .items .card:not(.no-hover):hover a:not(.btn):hover {
    color: var(--secondary-color);
}

footer .items .card a:not(.btn):hover i {
    color: var(--secondary-color);
}

footer a.navbar-brand:not(.btn) {
    padding: 0;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1;
    color: var(--nav-item-color);
}

footer .navbar-brand i {
    color: var(--primary-color);
}

footer .navbar-brand img {
    height: var(--nav-brand-height);
}

footer .navbar-brand .brand {
    letter-spacing: 5px;
    color: var(--secondary-p-color);
}

footer .navbar-brand .featured {
    position: relative;
    display: inline-block;
    padding: 13px 2px 14px 30px;
}

footer .navbar-brand .featured:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 7.5px);
    height: 100%;
    border-radius: 100px 0 0 100px;
    background-color: var(--secondary-color);
    opacity: 0.15;
}

footer .navbar-brand .featured .first {
    position: relative;
    margin-right: -10px;
    color: var(--secondary-color);
}

footer .brand .logo {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 0.8;
    color: var(--primary-color);
}

footer .brand .logo img {
    width: auto;
    height: var(--footer-brand-height);
}

footer .brand a:hover {
    text-decoration: none;
}

footer .title {
    margin-top: 0;
}

footer .contacts {
    position: relative;
    top: 2.5rem;
    left: 2.5rem;
}

footer .contacts .nav-item {
    position: relative;
    z-index: 1;
}

footer .contacts:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: -40px;
    right: 40px;
    width: 100%;
    height: 165%;
    border-top: 4px solid;
    border-right: 4px solid;
    border-color: var(--primary-t-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
}

footer .contacts:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -40px;
    right: 40px;
    width: 100%;
    height: 165%;
    border-left: 4px solid;
    border-bottom: 4px solid;
    border-color: var(--primary-t-color);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
}

footer .contacts:hover:before,
footer .contacts:hover:after {
    border-color: var(--primary-color);
}

footer .contacts li {
    margin: 0;
    font-size: 1.2rem;
}

footer .nav-item {
    margin: 0;
}

footer .nav-item .nav-link {
    font-weight: 400;
    line-height: 1.4;
    color: inherit;
}

footer.odd .nav-item .nav-link {
    color: var(--secondary-p-color);
}

footer .nav-item .nav-link:hover {
    color: var(--primary-color);
    outline: none;
}

footer .copyright {
    background-color: var(--primary-l-color);
}

footer .copyright p {
    font-size: 14px;
    line-height: 1.8;
}

/* #endregion Footer */

/*----------------------------------------------
12. Modal
----------------------------------------------*/

/* #region Modal */

.modal-open {
    overflow-y: hidden;
    overflow-x: hidden;
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(100%, 0)scale(1);
    transform: translate(100%, 0)scale(1);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    display: flex;
    align-items: start;
    -webkit-box-align: start;
    height: 100%;
}

.modal-content {
    border: none;
    border-radius: 0;
    overflow-x: hidden;
    background-color: var(--primary-bg-color);
}

.odd .modal-content {
    background-color: var(--secondary-bg-color-2);
}

.modal-content.full {
    height: 100%;
}

.modal-header {
    align-items: center;
    padding: 25px;
    border: none;
    font-weight: 700;
    color: var(--primary-t-color);
    border-radius: 0;
}

.odd .modal-header {
    color: var(--secondary-t-color);
}

.modal-header .modal-title {
    line-height: 0;
}

.modal-header .icon-close {
    font-size: 24px;
    cursor: pointer;
}

.modal-header .icon-close:hover {
    color: var(--primary-color);
}

.modal-header.absolute {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
}

.modal-header.absolute .icon-close {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 100px;
    background-color: var(--primary-bg-color-4);
    color: var(--white-color);
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.modal-header.absolute .icon-close:hover {
    background-color: var(--primary-color);
}

.modal-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 25px 25px 75px;
}

.modal-body iframe {
    width: 100%;
    height: 100%;
}

/* #endregion Modal */

/*----------------------------------------------
13. Miscellaneous
----------------------------------------------*/

/* #region Miscellaneous */

.spinner-grow {
    color: var(--primary-color);
}

.scroll-to-top {
    display: none;
    position: fixed;
    z-index: 5;
    right: 25px;
    bottom: 25px;
    opacity: 0.75;
    transition-timing-function: ease;
    transition-duration: .4s;
    transition-property: opacity, transform;
}

.scroll-to-top:hover {
    opacity: 1;
}

.scroll-to-top i {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 22px;
    background-color: var(--primary-bg-color-2);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.odd.scroll-to-top i {
    background-color: var(--secondary-bg-color-2);
}

.scroll-to-top i:hover,
.scroll-to-top i:focus {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* #endregion Miscellaneous */

/*----------------------------------------------
14. Keyframe
----------------------------------------------*/

/* #region Keyframe */

@-webkit-keyframes effect {
    to {
        background-position: -200% center;
    }
}

@keyframes effect {
    to {
        background-position: -200% center;
    }
}

/* #endregion Keyframe */

/*----------------------------------------------
15. All Media Queries
----------------------------------------------*/

/* #region All Media Queries */

@media(max-width: 1199px) {

    /* #region Header */

    body header .navbar-expand .navbar-nav.toggle {
        display: flex;
    }

    body header .navbar-expand .navbar-nav.toggle a:not(.btn) {
        padding-right: 0;
    }

    body header .navbar-expand .navbar-nav.contacts,
    body header .navbar-expand .navbar-nav.items,
    body header .navbar-expand .navbar-nav.icons .social,
    body header .navbar-expand .navbar-nav.action {
        display: none;
    }

    body header .navbar.top {
        display: none;
    }

    body header .navbar.sub {
        top: 0;
    }

    /* #endregion Header */

    /* #region Slider */

    body .full-slider .inner, body .no-slider .inner {
        padding: 0 25px;
    }

    body .full-slider .inner .right, .no-slider .inner .right {
        width: 65%;
    }

    body .full-slider.featured .inner .left:before,
    body .full-slider.featured .inner .left:after,
    body .no-slider.featured .inner .left:before,
    body .no-slider.featured .inner .left:after,
    body .full-slider.featured .inner .right:before,
    body .full-slider.featured .inner .right:after,
    body .no-slider.featured .inner .right:before,
    body .no-slider.featured .inner .right:after {
        content: none;
    }

    body .full-slider.featured .inner .left .title,
    body .no-slider.featured .inner .left .title,
    body .full-slider.featured .inner .right .title,
    body .no-slider.featured .inner .right .title {
        max-width: 100%;
    }

    .full-slider.featured .inner .right .description {
        right: initial;
        position: inherit;
    }

    body .full-slider .inner button {
        margin: auto;
    }

    body .full-slider.swiper-container-horizontal > .swiper-pagination-bullets {
        right: 25px;
    }

    body .full-slider .swiper-button-next {
        right: 0;
        padding: 1.5rem 1rem;
    }

    body .full-slider .swiper-button-prev {
        left: 0;
        padding: 1.5rem 1rem;
    }

    body .full-slider .swiper-button-next:after,
    body .full-slider .swiper-button-prev:after {
        font-size: 2rem;
    }

    /* #endregion Slider */

    /* #region Section */

    body .container {
        padding: 0 10px;
        max-width: 100%;
    }

    body .container.header.full-grid {
        margin: 0;
    }

    body .container.full-grid {
        padding: 0;
        max-width: calc(100% - 20px);
        margin: 0 10px;
    }

    /* #endregion Section */

    /* #region Feature */

    body .bricklayer-column-sizer {
        width: 33.3333%;
    }

    body .columns-1 .bricklayer-column-sizer {
        width: 100%;
    }

    body .columns-2 .bricklayer-column-sizer,
    body .columns-3 .bricklayer-column-sizer,
    body .columns-4 .bricklayer-column-sizer,
    body .columns-5 .bricklayer-column-sizer,
    body .columns-6 .bricklayer-column-sizer {
        width: 33.3333%;
    }

    body .card-columns {
        column-count: 3;
    }

    body .card-footer {
        margin: 0;
    }

    body .play-video {
        right: 40px;
        bottom: 30px;
        font-size: 2.5rem;
    }

    /* #endregion Feature */

    /* #region Gallery */

    body .gallery.featured a:first-child:before,
    body .gallery.featured a:last-child:before {
        content: none;
    }

    /* #endregion Gallery */
}

@media(max-width: 991px) {

    /* #region General */

    body h1,
    body .slide-content .title {
        font-size: 3.5rem;
    }

    body h2 {
        font-size: 2rem;
    }

    /* #endregion General */

    /* #region Slider */

    body .slider-h-100 {
        height: auto;
    }

    body .slider-h-100.alt {
        height: auto;
    }

    body .slider-h-100.slider-h-auto.alt {
        height: auto;
    }

    /* #endregion Slider */

    /* #region Section */

    body aside {
        margin-top: 60px;
    }

    body .team .quote {
        height: auto;
    }

    body .subscribe .items .item {
        padding: 0;
    }

    body .contact form {
        width: 100%;
        margin-bottom: 35px;
    }

    body .process .items:before {
        content: none;
    }

    body .footer {
        text-align: center;
    }

    body .footer .navbar-nav {
        text-align: center;
    }

    body .footer .btn {
        margin: 10px auto 50px;
    }

    body footer .card i {
        display: none;
    }

    /* #endregion Section */

    /* #region Feature */

    body .bricklayer-column-sizer {
        width: 50%;
    }

    body .columns-1 .bricklayer-column-sizer {
        width: 100%;
    }

    body .columns-2 .bricklayer-column-sizer,
    body .columns-3 .bricklayer-column-sizer,
    body .columns-4 .bricklayer-column-sizer,
    body .columns-5 .bricklayer-column-sizer,
    body .columns-6 .bricklayer-column-sizer {
        width: 50%;
    }

    body .card-columns {
        column-count: 2;
    }

    /* #endregion Feature */
}

@media(max-width: 767px) {

    /* #region General */

    body h1,
    body .slide-content .title {
        font-size: 3rem;
    }

    /* #endregion General */

    /* #region Helper Class */

    body .intro .btn {
        margin-top: 30px;
    }

    body .highlights:not(.image-right):not(.image-center) .intro {
        margin-top: 50px;
    }

    body .highlights .items .item:last-child {
        margin-bottom: 35px;
    }

    body .highlights:not(.image-left) .items:last-child .item:last-child {
        margin-bottom: 70px;
    }

    body .offers .items .item {
        margin-bottom: 0;
    }

    body .offers .items .item {
        margin-bottom: 30px;
    }

    body .blog-grid:not(.masonry) .items .item {
        margin-bottom: 0;
    }

    body .blog-grid:not(.masonry) .items .item {
        margin-bottom: 30px;
    }

    /* #endregion Helper Class

    /* #region Header */

    body header .navbar-expand .navbar-nav.action .btn {
        padding: 5px 10px;
        font-size: 0;
    }

    body header .navbar-expand .navbar-nav.action .btn i {
        font-size: initial;
        margin: 0;
    }

    body .navbar-brand {
        margin: 0;
    }

    /* #endregion Header */

    /* #region Slider */

    body .full-slider .slide-content .inner .left,
    body .no-slider .slide-content .inner .left,
    body .full-slider .slide-content .inner .right,
    body .no-slider .slide-content .inner .right,
    body .full-slider .slide-content .inner .center,
    body .no-slider .slide-content .inner .center {
        width: 90%;
    }

    body .no-slider .slide-content .inner .right.alt {
        width: 100%;
    }

    body .full-slider .slide-content .inner .left a.btn-primary-color:after,
    body .no-slider .slide-content .inner .left button.btn-primary-color:after,
    body .full-slider .slide-content .inner .right a.btn-primary-color:after,
    body .no-slider .slide-content .inner .right button.btn-primary-color:after {
        content: none;
    }

    body .full-slider .hero-image,
    body .no-slider .hero-image {
        right: -15%;
    }

    body .full-slider .hero-image-left,
    body .no-slider .hero-image-left {
        left: initial;
    }

    body .full-slider .hero-image.alt,
    body .no-slider .hero-image.alt {
        top: 22%;
    }

    body .full-slider .hero-image-left.alt,
    body .no-slider .hero-image-left.alt {
        top: 22%;
    }

    body .full-slider .slide-content .inner .description,
    body .no-slider .slide-content .inner .description {
        max-width: 100%;
        font-size: 1rem;
    }

    body .slide-content .buttons .btn {
        min-width: 180px;
        font-size: 0.9rem;
    }

    /* #endregion Slider */

    /* #region Section */

    body section {
        padding: 90px 0;
    }

    body section.bg-image-1,
    body section.bg-image-2 {
        background-image: none;
    }

    body .highlights.image-right .image {
        position: relative;
    }

    body .highlights.image-right .image img {
        width: calc(100% - 30px);
        height: auto;
        margin: 0 15px;
    }

    body footer .navbar-nav {
        margin-bottom: 35px;
    }

    /* #endregion Section */

    /* #region List */

    body .list-group-item img {
        margin-right: 90px;
    }

    /* #endregion List */

    /* #region Features */

    body .filter-section .btn-group .btn {
        font-size: 1rem;
    }

    /* #endregion Features */

    /* #region Multi-Step Form */

    body .form .message {
        width: calc(100% - 30px);
    }

    body .step-prev, body .step-next, body .send {
        width: calc(50% - 12px);
    }

    /* #endregion Multi-Step Form */

    /* #region Footer */

    body footer .contacts {
        left: 0;
    }

    body footer .contacts {
        margin-bottom: 30px;
    }

    body footer .contacts:after {
        left: 0;
        width: 100%;
    }

    body footer .contacts:before {
        left: 0;
        width: 100%;
    }

    /* #endregion Footer */
}

@media (max-width: 576px) {

    /* #region Feature */

    body .bricklayer-column-sizer {
        width: 100%;
    }

    body .columns-1 .bricklayer-column-sizer,
    body .columns-2 .bricklayer-column-sizer,
    body .columns-3 .bricklayer-column-sizer,
    body .columns-4 .bricklayer-column-sizer,
    body .columns-5 .bricklayer-column-sizer,
    body .columns-6 .bricklayer-column-sizer {
        width: 100%;
    }

    body .card-columns {
        column-count: 1;
    }

    /* #endregion Feature */

    /* #region Modal */

    body .modal-body {
        align-items: start;
    }

    /* #endregion Modal */
}

@media(max-width: 374px) {

    /* #region General */

    body h1,
    body .slide-content .title {
        font-size: 2.5rem;
    }

    /* #endregion General */
}

/* #endregion All Media Queries */